<template>
	<main class="page-container" id="sme-unsubscribe">
		<div class="auth-component">
			<header class="auth-header">
				<h2 class="title">가치삽시다 마케팅 수신을 거부하시겠습니까?</h2>
			</header>
			<div class="auth-body text-center">
				<p class="mb-4 auth-body-desc">
					수신거부 후, 다시 마케팅을 수신하고 싶으시다면 가치삽시다에 접속하여 로그인 후<br />
					<strong>‘마이페이지’ -> ‘내 정보 변경’</strong>에서 마케팅 수신여부를 변경해주시기 바랍니다.
				</p>
				<div class="button-wrap flex justify-content-center gap-10">
					<button type="button" class="button-default is-rounded is-large is-secondary" @click="marketingUnscribeClick">예</button>
					<button type="button" class="button-default is-rounded is-large is-outlined" @click="cancelClick">아니오</button>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { MUT_SHOW_ALERT } from '../../../store/_mut_consts';
import { ACT_UPDATE_OUT_MKT_RCPTN_AGRE_YN } from '../../../store/_act_consts';
import { isSuccess, strToHex, hexToStr } from '../../../assets/js/utils';
import { mapGetters } from 'vuex';

export default {
	name: 'MarketingUnsubscribe',
	data: () => ({
		userId: '',
	}),
	computed: {},
	created() {
		this.userId = hexToStr(this.$route.params.userData);
	},
	methods: {
		marketingUnscribeClick() {
			this.$store.dispatch(`auth/${ACT_UPDATE_OUT_MKT_RCPTN_AGRE_YN}`, {
        userData: strToHex(this.userId),
        mktAgreYn: 'N',
      }).then(res => {
        if(isSuccess(res)){
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: '마케팅 수신 동의여부가 수정되었습니다.',
						yesfunc: this.winClose(),
            html:true,
					});
        }else{
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: '마케팅 수신 동의여부 수정이 실패하였습니다.',
						yesfunc: this.winClose(),
            html:true,
					});
        }
      }).catch(e => {
        console.error(e);
      });
		},
		cancelClick() {
			window.close();
		},
		winClose(){
			setTimeout(() => {
				window.close();
			}, 2000);
		},
	},
};
</script>
